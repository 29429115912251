/*
 * @Author: xuhongwei
 * @Date: 2019-07-01 14:55:39
 * @LastEditTime: 2020-03-04 11:27:18
 * @LastEditors: xuhongwei
 * @Description: 
 */
import ajax from './axios'
// 完善信息
export const garagePerfectGarageDetail = params => {
  return ajax.post('/user/garage/perfectGarageDetail', params)
}
// 代理商邀请汽修厂发送验证码
export const getMiniAgencyCode = params => {
  return ajax.post('/user/garage/miniAgency/code', params)
}
// 获取代理商邀请汽修厂的图片验证码
export const getMiniAgencyImgCode = params => {
  return ajax.post(`/user/garage/miniAgency/imgCode?phone=${params}`)
}
// 注册获取验证码
export const getVcodeToRegistered = params => {
  return ajax.post('/user/garage/sendVcodeToRegistered', params)
}

// 验证验证码
export const checkVcode = params => {
  return ajax.post('/user/garage/checkVCode', params)
}

// 完成注册大大汽修
export const garageRegistered = params => {
  return ajax.post('/user/garage/garageRegistered', params)
}

// 获取userid
export const getUserId = () => {
  return ajax.get('/user/getUserId')
}

// 获取验证码
export const sendVcode = params => {
  return ajax.post('/garage/garage/manage/sendVcodeH5', params)
}

// 获取验证码
export const vcodeLogin = params => {
  return ajax.post('/garage/garage/manage/vcodeLoginH5', params)
}

// 获取下载链接
export const downLoadUrl = params => {
  return ajax.post('/user/app/version/getNowAppVersion', params)
}

// 通过code获取用户手机号信息以及用户是否绑定
export const getUserStatusByCode = params => {
  return ajax.post('/user/weChat/owner/login', params)
}

// 大大养车公众号发送短信验证码
export const sendCellPhoneCode = params => {
  return ajax.post('/user/weChat/send/owner/code', params)
}

// 大大养车公众号绑定手机号
export const bindPhone = params => {
  return ajax.post('/user/weChat/owner/bindPhone', params)
}

// 大大汽修公众号获取验证码
export const bindSendVCode = phone => {
  return ajax.post(`/garage/club/wx/bind/sendVCode?phone=${phone}`)
}

// 通过手机号绑定修理厂
export const getClubWxBind = params => {
  return ajax.post('/garage/club/wx/bind', params)
}

// 判断用户是否绑定公众号
export const clubWxIsBind = params => {
  return ajax.post('/garage/club/wx/isBind', params)
}

// 获取jsapi签名
export const wxGetJsApiSign = url => {
  return ajax.get(`/garage/club/wx/getJsApiSign?url=${url}`)
}

// 获取安检提醒
export const getQuotesSecurityReminder = params => {
  return ajax.post(`/garage/smart/quotes/securityReminder`, params)
}