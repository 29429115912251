<template>
  <div>
    <div class="login-background">
      <div class="login-footer" @click="activetyRules">
        登录代表同意《活动规则》
      </div>
    </div>
    <div class="login-box">
      <h1>登录</h1>
      <ul>
        <li>
          <cube-input class="login-input" placeholder="请输入手机号码" v-model="mobilePhone"></cube-input>
          <span v-if="sendCaptchaEnabled" class="get-code" @click="sendCaptcha()">{{getVcode}}</span>
          <span v-else class="after-get-code">{{counterTimer}}秒后重新获取</span>
          <li>
            <li>
              <cube-input class="login-input" placeholder="请输入手机验证码" v-model="vcode"></cube-input>
            </li>
      </ul>
      <div class="download-btn-red" @click="loggingOn">
        登录
      </div>
    </div>

  </div>
</template>

<script>
import { sendVcode, vcodeLogin } from 'api/user'
import { setSessionStorage, getSessionStorage, setCookie } from 'utils/store'
export default {
  data () {
    return {
      mobilePhone: '',
      vcode: '',
      sendCaptchaEnabled: true,
      sending: false,
      getVcode: '获取验证码',
      counterTimer: 60
    }
  },
  methods: {
    activetyRules () {
      this.$router.push({ name: '活动规则' })
    },
    sendCaptcha () {
      if (!this.mobilePhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.mobilePhone.trim())) {
        const toast = this.$createToast({
          txt: `请正确输入11位数字的手机号`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.sendCaptchaEnabled) {
        return
      }
      if (this.sending) {
        return
      }
      this.sending = true
      let params = {
        phone: this.mobilePhone
      }
      sendVcode(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.sendCaptchaEnabled = false
        this.timer = setInterval(() => {
          if (this.counterTimer <= 0) {
            this.counterTimer = 60
            this.sendCaptchaEnabled = true
            this.getVcode = '重新获取'
            clearInterval(this.timer)
          }
          this.counterTimer--
        }, 1000)
      })
    },
    loggingOn () {
      if (!this.mobilePhone.trim()) {
        const toast = this.$createToast({
          txt: `手机号码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!/^1\d{10}$/.test(this.mobilePhone.trim())) {
        const toast = this.$createToast({
          txt: `请正确输入11位数字的手机号`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (!this.vcode.trim()) {
        const toast = this.$createToast({
          txt: `验证码不能为空`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.vcode.trim().length < 6) {
        const toast = this.$createToast({
          txt: `验证码不正确`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return
      }
      if (this.sending) {
        return
      }
      let params = {
        phone: this.mobilePhone,
        vcode: this.vcode,
        userId: getSessionStorage('fromUser')
      }
      this.sending = true
      vcodeLogin(JSON.stringify(params)).then((res) => {
        this.sending = false
        let { result, desc, data } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        let token = data.token
        setSessionStorage('token', token)
        setCookie('token', token, 1)
        this.$store.commit('SHOULD_SHOW_APP_DOWNLOAD', true)
        this.$router.push({ path: '/dc/home' })
      })
    }
  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
.login-background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100vh;
  top: 0px;
  bottom: 0px;
  background: url(~images/dow-ceremony/download-bg.png) no-repeat;
  background-size: cover;
}

.login-box {
  margin: px2rem(220px) px2rem(50px);
  background: #fff;
  display: inline-block;
  z-index: 10;
  border-radius: 6px;
  & h1 {
    color: #4e4e4e;
    font-size: px2rem(42px);
    margin: px2rem(66px) 0 px2rem(23px) 0;
    text-align: center;
    width: px2rem(650px);
  }
  & li {
    position: relative;
    margin: px2rem(57px) 0 0 0;
    & span {
      font-size: px2rem(32px);
      color: $color-highlight;
      position: absolute;
      right: px2rem(52px);
      height: px2rem(42px);
      line-height: px2rem(42px);
      top: 50%;
      transform: translate(0, -50%);
    }
    & .login-input {
      width: px2rem(540px);
      height: px2rem(42px);
      line-height: px2rem(42px);
      color: #999;
      display: inline-block;
      margin: 0 px2rem(55px);
      font-size: px2rem(32px);
      padding: 0 0 px2rem(23px) px2rem(2px);
    }
  }
}
.download-btn-red {
  width: px2rem(540px);
  height: px2rem(88px);
  background: #fc4c5a;
  border-radius: px2rem(45px);
  font-size: px2rem(32px);
  line-height: px2rem(88px);
  color: #fff;
  text-align: center;
  margin: px2rem(60px) auto;
}
.login-footer {
  color: #fff;
  position: fixed;
  z-index: 1;
  bottom: px2rem(30px);
  font-size: px2rem(24px);
  width: px2rem(750px);
  text-align: center;
}
</style>
